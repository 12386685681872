@import url("https://fonts.googleapis.com/css2?family=Exo:wght@300;400;500&display=swap");

:root {
  /* --primary-color: #9ccc3d; */
  --primary-color: violet;
  --background-color: rgba(43, 43, 55, 0.7);
  --dark-background-color: rgb(0, 0, 0);
  --font: "Exo", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  /* background-image: url("assets/background.jpg"); */
  background-image: url("https://static.vecteezy.com/system/resources/previews/013/630/282/non_2x/interesting-gradient-design-purple-black-free-photo.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  /* font-family: var(--font); */
  height: max-content;
  overflow-x: hidden;
}

::-webkit-scrollbar {
}

::-webkit-scrollbar-thumb {
}

/* linear-gradient(270deg, #f60, #7b16d9); */