/* ActiveListeners.css */
#activelisteners.section {
  border-bottom: 0.1rem solid #242424;
  color: white;
}

#activelisteners .title-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  padding-top: 1rem;
}

#activelisteners .title h4 {
  font-size: 0.8rem;
  margin-bottom: 0.2rem;
}

#activelisteners .title h1 {
  font-size: 1.5rem;
  letter-spacing: 0.2rem;
}

#activelisteners .chart {
  position: relative;
}

#activelisteners .percent {
  position: absolute;
  top: 0;
  left: 0;
  color: var(--primary-color);
  font-size: 0.8rem;
}

#activelisteners .chart {
  height: 4rem;
  width: 100%;
}

#activelisteners .active {
  max-height: 11rem;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-right: 1.5rem;
  margin: 1rem 0;
}

#activelisteners .active::-webkit-scrollbar {
  width: 0.2rem;
  background-color: #6e6e6ec3;
}

#activelisteners .active::-webkit-scrollbar-thumb {
  background-color: #b8b8b8;
}

#activelisteners .active h5 {
  font-weight: 100;
}

#activelisteners .country {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#activelisteners .name {
  display: flex;
  gap: 1rem;
  align-items: center;
}

#activelisteners .name img {
  height: 2rem;
  border-radius: 2rem;
}
