/* TopReleases.css */
#toprelease.section {
  /* Assuming applyCardStyles applies some reusable card styles */
  color: white;
}

#toprelease .title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

#toprelease .title-container .filters {
  display: flex;
  align-items: center;
  gap: 3rem;
  color: var(--primary-color);
}

#toprelease .title-container .filters button {
  background-color: var(--primary-color);
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem 0.8rem;
  cursor: pointer;
  font-weight: bolder;
}

#toprelease .musics {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

#toprelease .musics .music {
  border-bottom: 0.1rem solid #242424;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#toprelease .musics .music .details {
  display: flex;
  gap: 1rem;
}

#toprelease .musics .music .details .image img {
  height: 2.5rem;
}

#toprelease .musics .music .details .info {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

#toprelease .musics .music .details .info h6 {
  font-weight: 100;
}

@media screen and (min-width: 280px) and (max-width: 1080px) {
  #toprelease .title-container {
    flex-direction: column;
    gap: 0.5rem;
  }

  #toprelease .title-container .filters {
    flex-direction: column;
    gap: 0.5rem;
  }

  #toprelease .musics {
    grid-template-columns: 1fr;
  }
}
