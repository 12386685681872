.album-banner {
  position: relative;
  height: 100vh;
  /* overflow: auto;
    scrollbar-width: none; */
}
.album-image {
  height: 100vh;
  width: 40%;
  position: absolute;
  top: 0;
  left: 0;
}
.back-img {
  height: 100%;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgb(255 255 255 / 30%);
}
.album-image .back-img {
  background-position: top center;
}
.album-banner .row {
  align-items: center;
  height: 100vh;
}
.album-details {
  position: relative;
  max-height: 90vh;
  margin-left: 40px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  overflow-y: auto;
  pointer-events: auto;
  scrollbar-width: none;
}
.title {
  text-align: center;
}
.album-details .title {
  text-align: left;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff;
  /* font-family: var(--title-font); */
  font-weight: 300;
  position: relative;
  pointer-events: auto;
}
.h1-title {
  font-size: 60px;
  line-height: 80px;
  text-transform: capitalize;
  font-weight: bold;
  color: #fff;
}
.album-details .h1-title {
  margin-bottom: 0;
}
.small-text {
  position: absolute;
  display: block;
  width: 100%;
  margin: 0;
  top: calc(50% - 15px);
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  /* font-family: var(--title-font); */
  opacity: 1;
  transition: all 0.5s;
}
.album-details .small-text {
  position: relative;
  top: 0;
  left: 0;
  color: #fff;
  font-size: 20px;
  font-weight: 300;
  margin-left: 3px;
}
.album-songs-detail {
  display: flex;
  margin: 60px 0 20px;
}
.popular-songs,
.similar-artists {
  width: 50%;
  position: relative;
}
.popular-songs h4,
.similar-artists h4 {
  font-size: 20px;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
}

@media (min-width: 1200px) {
  .h4,
  h4 {
    font-size: 1.5rem;
  }
}
ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.music-list-box {
  padding: 30px 4em 30px 30px;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  overflow: hidden;
  border-radius: 20px;
  z-index: 2;
  box-shadow: 10px 10px 19px #282e61, -10px -10px 19px #363e83;
}
.popular-songs li {
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid rgb(255 255 255 / 6%);
  margin-bottom: 20px;
}
.popular-songs .music-list-box {
  box-shadow: none;
  border-radius: 0;
}
.songs-name {
  width: 70%;
  display: flex;
  align-items: center;
}
.songs-name .back-img {
  height: 50px;
  width: 50px;
  max-width: 50px;
  border-radius: 10px;
  box-shadow: 2px 2px 6px rgb(0 0 0 / 30%);
}
.songs-name .h3-title {
  font-size: 18px;
  line-height: 30px;
  font-weight: normal;
  margin-bottom: 0;
  margin-left: 15px;
  color: #fff;
  text-transform: capitalize;
}
.audio-box {
  width: 30%;
}
.music-controls {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
  margin-inline: auto;
  width: 270px;
}
.audio-box .music-controls {
  width: auto;
  padding: 0;
  justify-content: flex-end;
}
.music-controls-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  padding: 6px;
  border-radius: 50%;
  cursor: pointer;
  transition: ease-in-out 0.2s;
  pointer-events: auto;
}
.similar-artists {
  margin-left: 30px;
  margin-right: 30px;
  padding-left: 30px;
  border-left: 1px solid rgb(255 255 255 / 6%);
}
.popular-songs h4,
.similar-artists h4 {
  font-size: 20px;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
}
.similar-artists h4 {
  margin-bottom: 13px;
}
.similar-artists li {
  padding: 15px 0;
}
.songs-name {
  width: 70%;
  display: flex;
  align-items: center;
}
a {
  background-color: transparent;
  text-decoration: none !important;
  outline: none !important;
  pointer-events: auto;
}
.songs-name.similar-artists-box a {
  display: inline-flex;
  pointer-events: auto;
}
.similar-artists-box .back-img {
  height: 60px;
  width: 60px;
  max-width: 60px;
  border-radius: 100%;
  box-shadow: 4px 4px 20px rgb(0 0 0 / 50%);
  position: relative;
}
.similar-artists-box .back-img:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70px;
  width: 70px;
  background-color: transparent;
  border: 1px solid #fff;
  z-index: 0;
  border-radius: 100%;
}
.similar-artists-text {
  margin-left: 30px;
  padding-top: 8px;
}
.similar-artists-box .h3-title {
  margin-left: 0;
  line-height: 20px;
}
.similar-artists-text .small-text {
  font-size: 14px;
  line-height: 24px;
}
.music-info {
  padding-inline: 20px;
  position: relative;
  z-index: 1;
}
.music-name {
  font-size: 1.4em;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 4px;
  line-height: 1;
  text-transform: capitalize;
}
.music-artist {
  font-size: 1em;
  color: #fff;
  text-transform: capitalize;
}
p:last-child {
  margin-bottom: 0;
}
.music-progress {
  position: relative;
  width: calc(100% - 40px);
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  cursor: pointer;
  z-index: 1;
}
.music-progress:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 5px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  z-index: -1;
  pointer-events: auto;
}
.music-progress-bar {
  position: relative;
  width: 0;
  height: 5px;
  border-radius: 5px;
  background-color: #fff;
  pointer-events: auto;
}
.music-progress-bar:after {
  content: "";
  position: absolute;
  right: -6px;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  background: rgba(255, 255, 255, 1);
  filter: drop-shadow(0px 0px 4px rgba(46, 45, 45, 1));
  border-radius: 50%;
  box-sizing: border-box;
  pointer-events: auto;
}
.music-progress__time {
  position: absolute;
  top: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.music-progress__time-item {
  color: #fff;
  font-size: 12px;
  opacity: 0.4;
}
.music-controls-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  padding: 6px;
  border-radius: 50%;
  cursor: pointer;
  transition: ease-in-out 0.2s;
  pointer-events: auto;
}
.play-icon-background {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: violet;
  z-index: 0;
  /* opacity: 0; */
  pointer-events: none;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
  transition: all 0.2s;
}
