.artist-profile p,
.artist-profile h1,
.artist-profile h2,
.artist-profile h3,
.artist-profile h4,
.artist-profile h5,
.artist-profile h6,
.artist-profile div {
  color: #fff;
}
.artist-profile .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.artist-profile .card {
  border: 0 solid #e5e5e5;
  margin-bottom: 40px;
  border-radius: 16px;
}
.artist-profile .card,
.artist-profile .card-header {
  background: transparent;
}
.artist-profile .card-title {
  /* font-size: 18px; */
  margin-bottom: 20px !important;
  color: #fff;
}
.artist-profile .card-body {
  flex: 1 1 auto;
  padding: 1rem;
}
.artist-profile .card .card-body {
  padding: 30px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 0 20px rgba(89, 102, 122, 0.05);
}
@media only screen and (min-width: 1200px) {
  .artist-profile .card .card-body {
    padding: 20px;
  }
}
.artist-profile .bid-table table td,
.artist-profile .card .card-body,
.artist-profile .stat-widget,
.artist-profile .top-creators-content .creator-widget {
  background: #1d1933;
}
.artist-profile .rounded {
  border-radius: 16px !important;
}
.artist-profile .top-bid .avatar-img {
  border-radius: 100px;
  max-width: 30px;
}
.artist-profile .me-3 {
  margin-right: 1rem !important;
}
.artist-profile .flex-grow-1 {
  flex-grow: 1 !important;
}
.artist-profile .top-bid .h6,
.artist-profile .top-bid h6 {
  font-size: 14px;
}
.artist-profile .h1,
.artist-profile .h2,
.artist-profile .h3,
.artist-profile .h4,
.artist-profile .h5,
.artist-profile .h6,
.artist-profile b,
.artist-profile h1,
.artist-profile h2,
.artist-profile h3,
.artist-profile h4,
.artist-profile h5,
.artist-profile h6,
.artist-profile strong {
  color: #fff;
}
.artist-profile .top-bid .circle {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100px;
  margin-left: 10px;
}
.artist-profile .bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(81, 187, 37, 1) !important;
}
.artist-profile .text-muted {
  --bs-text-opacity: 1;
  color: #7184ad !important;
}
.artist-profile .text-end {
  text-align: right !important;
}
.artist-profile .text-primary {
  --bs-text-opacity: 1;
  color: rgba(111, 78, 242, 1) !important;
}
.artist-profile .btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #7e7e7e;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  border-radius: 16px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.artist-profile .btn-primary {
  color: #fff;
  background-color: #6f4ef2;
  border-color: #6f4ef2;
}
.artist-profile .btn,
.artist-profile button {
  color: #fff;
  padding: 8px 30px;
  font-weight: 700;
  border-radius: 12px;
}
.artist-profile .top-bid .btn {
  min-width: 45%;
  margin: 5px;
  font-size: 14px;
  padding: 12px;
}
.artist-profile .btn-secondary {
  color: #000;
  background-color: #f73164;
  border-color: #f73164;
}
.artist-profile .card-header:first-child {
  border-radius: 15px 15px 0 0;
}
.artist-profile .filter-nav a {
  display: inline-block;
  padding: 3px 20px;
  margin-left: 10px;
  border-radius: 16px;
  color: #070707;
}
.artist-profile .filter-nav a {
  color: #e3ddfc;
  text-decoration: none;
}
.artist-profile .filter-nav a.active {
  background: #6f4ef2;
  color: #fff;
}
.artist-profile .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0 solid #e5e5e5;
  padding: 30px 0 20px;
}
.artist-profile .bg-transparent {
  background-color: transparent !important;
}
.artist-profile .items .creator {
  position: absolute;
  bottom: -5px;
  right: 20px;
  border-radius: 100px;
}
.artist-profile .stat-widget {
  background: #fff;
  padding: 20px;
  border-radius: 16px;
  margin-bottom: 30px;
  position: relative;
  box-shadow: 0 0 20px rgba(89, 102, 122, 0.05);
}
.artist-profile .bid-table table td,
.artist-profile .card .card-body,
.artist-profile .stat-widget,
.artist-profile .top-creators-content .creator-widget {
  background: #1d1933;
}
.artist-profile .stat-widget .widget-icon {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: 20px;
  color: #fff;
}
.artist-profile .stat-widget .widget-content .h3,
.artist-profile .stat-widget .widget-content h3 {
  font-size: 20px;
  margin-bottom: 0;
}
.artist-profile .stat-widget .widget-content p {
  color: #7e7e7e;
  opacity: 0.85;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
}
.artist-profile .stat-widget .widget-arrow {
  position: absolute;
  right: 26px;
  font-weight: 500;
}
.artist-profile .text-success {
  --bs-text-opacity: 1;
  color: rgba(81, 187, 37, 1) !important;
}
.artist-profile .btn-danger{
  background-color: #bb2d3b;
}


.artist-profile .topbidd .btn {
  min-width: 30%;
  margin: 5px;
  font-size: 14px;
  padding: 12px;
}