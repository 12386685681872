/* RightSidebar.css */
.right-sidebar {
    height: 100%;
    width: 100%;
    /* background-color: var(--dark-background-color); */
    background-color: transparent;
    padding: 0 3rem;
    padding-top: 2rem;
  }
  
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    .right-sidebar {
      padding: 0 2rem;
    }
  }
  