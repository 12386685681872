/* Avatar.css */
.avatar {
  color: white;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-bottom: 0.1rem solid #242424;
  padding-bottom: 1rem;
}

.avatar .image img {
  height: 3rem;
  border-radius: 2rem;
}

.avatar .name {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.avatar .name svg {
  color: var(--primary-color);
  font-size: 1.3rem;
}
