.artists-table .card {
  /* margin-bottom: 1.875rem; */
  background-color: var(--dark-background-color);
  transition: all 0.5s ease-in-out;
  position: relative;
  border: 0rem solid transparent;
  border-radius: 0.75rem;
  box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
  /* height: calc(100% - 30px); */
}
.artists-table .card-header {
  border-color: #d7dae3;
  position: relative;
  background: transparent;
  padding: 1.5rem 1.875rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.artists-table .card-title {
  font-size: 2.25rem;
  font-weight: 700;
  color: #fff;
  text-transform: capitalize;
}
.artists-table .card-header .card-title {
  margin-bottom: 0rem;
}
.artists-table .card-body {
  padding: 1.875rem;
}
.artists-table .table-responsive-md {
  min-width: 36rem !important;
}
.artists-table .table th,
.table td {
  /* border-color: #d7dae3; */
  padding: 0.9375rem 0.625rem;
}
.artists-table .table thead th {
  /* border-bottom: 0.0625rem solid #eeeeee; */
  text-transform: capitalize;
  /* color: #000; */
  font-size: 1.125rem;
  white-space: nowrap;
  font-weight: 500;
  letter-spacing: 0.0313rem;
  /* border-color: #d7dae3 !important; */
}
.artists-table .table:not(.table-bordered) thead th {
  border-top: none;
}
.artists-table .form-check {
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.artists-table .me-3 {
  margin-right: 1rem !important;
}
.artists-table .form-check {
  line-height: normal;
}
.artists-table .form-check-input {
  top: 0.125rem;
  border-width: 0.125rem;
  width: 1.25rem;
  height: 1.25rem;
  /* border-color: #e7e7e7; */
  background: transparent;
}
.artists-table .form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}
.artists-table .form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}
.artists-table .check-lg .form-check-input {
  width: 1.5rem;
  height: 1.5rem;
}
.artists-table .form-check-label {
  margin-left: 0.3125rem;
  margin-top: 0.1875rem;
}
.artists-table .table tbody tr td {
  vertical-align: middle;
  /* border-color: #d7dae3; */
}
.artists-table .table strong {
  /* color: #6e6e6e; */
}
.artists-table img,
.artists-table svg {
  vertical-align: middle;
}
.artists-table .me-2 {
  margin-right: 0.5rem !important;
}
.artists-table .rounded-lg {
  border-radius: 0.5rem;
}
.artists-table .rounded-lg {
  min-width: 30px;
}
.artists-table .w-space-no {
  white-space: nowrap;
}
.artists-table .btn {
  /* padding: 0.938rem 1.5rem; */
  border-radius: 0.5rem;
  font-weight: 400;
  font-size: 1rem;
}
@media only screen and (max-width: 1400px) {
  .artists-table .btn {
    padding: 0.625rem 1rem;
    font-size: 0.813rem;
  }
}
.artists-table .btn-primary {
  border-color: #76009f;
  background-color: #76009f;
}
.artists-table .sharp {
  min-width: 2.5rem;
  padding: 0.4375rem;
  height: 2.5rem;
  min-height: 2.5rem;
}
.artists-table .btn-xs {
  font-size: 0.75rem;
  padding: 0.438rem 1rem;
  font-weight: 600;
}
.artists-table .btn.btn-success,
.artists-table .btn.btn-secondary,
.artists-table .btn.btn-warning,
.artists-table .btn.btn-primary,
.artists-table .btn.btn-danger,
.artists-table .btn.btn-info {
  /* color: #fff; */
}
.artists-table .sharp.btn-xs {
  padding: 0.1875rem;
  width: 1.625rem;
  height: 1.625rem;
  min-width: 1.625rem;
  min-height: 1.625rem;
}
.artists-table .shadow.btn-primary {
  -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 var(--rgba-primary-2) !important;
  box-shadow: 0 0.3125rem 0.9375rem 0 var(--rgba-primary-2) !important;
}

.artists-table .add_artist_btn{
  font-size: 16px !important;
  padding: 11px 20px !important;
  font-weight: 500 !important;
}

.change_status_modal .modal-content{
  background-color: #000 !important;
}

.change_status_modal .modal-header .btn-close{
  filter: invert(1);
}