.home-container {
  display: grid;
  grid-template-columns: 12fr;
  min-height: 100vh;
  height: max-content;
}

@media screen and (min-width: 280px) and (max-width: 1080px) {
  .home-container {
    grid-template-columns: 1fr;
    height: max-content;
  }
}
