/* Navbar.css */
#navbar.nav {
  display: flex;
  justify-content: space-between;
  color: white;
  margin: 2rem;
}

#navbar .timeline {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#navbar .timeline svg {
  color: var(--primary-color);
  font-size: 2rem;
}

@media screen and (min-width: 280px) and (max-width: 1080px) {
  #navbar.nav {
    flex-direction: column;
    align-items: center;
    margin: 1rem;
    margin-bottom: 0;
  }

  #navbar .timeline {
    gap: 1rem;
  }
}
