/* Sidebar.css */

#sidebar {
  background-color: var(--dark-background-color);
  height: max-content;
  min-height: 100vh;
  width: max-content;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

#sidebar .help {
  transition: 0.5s ease-in-out;
  cursor: pointer;
  color: white;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem;
}

#sidebar .help a {
  text-decoration: none;
}

#sidebar .help:hover svg {
  color: var(--primary-color);
}

#sidebar svg {
  color: var(--primary-color);
  font-size: 1.5rem;
  margin-inline: 15px;
}

#sidebar .brand svg {
  font-size: 2.5rem;
}

#sidebar .links {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  list-style-type: none;
  align-items: flex-start;
}

#sidebar .links li {
  border-radius: 1rem;
  padding: 0.5rem;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}

#sidebar .links a {
  text-decoration: none;
  text-align: center;
  display: flex;
  align-items: center;
}

#sidebar .links li svg {
  color: white;
  margin-inline: 15px;
}

#sidebar .links li:hover {
  box-shadow: 0 0 60px 8px var(--primary-color);
}

#sidebar .links li:hover svg {
  color: var(--primary-color);
}

#sidebar .links .selected {
  box-shadow: 0 0 60px 8px var(--primary-color);
}

#sidebar .links .selected svg {
  color: var(--primary-color);
  background-color: transparent;
}

@media screen and (min-width: 280px) and (max-width: 1080px) {
  #sidebar {
    width: 100%;
    padding: 1rem;
  }

  #sidebar .links,
  #sidebar .help {
    display: none;
  }
}

#sidebar .show_on_hover {
  color: #fff;
}

/* #sidebar .show_on_hover {
  display: none;
} */

#sidebar:hover .show_on_hover {
  display: inline; /* Show the text on hover */
}

#sidebar .logo_img {
  /* width: 50px; */
  /* display: none; */
  width: 210px;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
}

/* #sidebar:hover .logo_img{
  width: 120px;
  display: block;
} */

#sidebar .logo_icon {
  width: 100%;
}

#sidebar:hover .logo_icon {
  /* width: 100%; */
  display: none;
}
