.add-artist *{
    color: #fff;
}

.add-artist .sw {
  position: relative;
}

.add-artist .sw *,
.add-artist .sw ::after,
.add-artist .sw ::before {
  box-sizing: border-box;
}

.add-artist .sw > .tab-content {
  position: relative;
  overflow: hidden;
}

.add-artist .sw .toolbar {
  padding: 0.8rem;
}

.add-artist .sw .toolbar > .btn {
  display: inline-block;
  text-decoration: none;
  text-align: center;
  text-transform: none;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  cursor: pointer;
}

.add-artist .sw .toolbar > .btn.disabled,
.add-artist .sw .toolbar > .btn:disabled {
  opacity: 0.65;
}

.add-artist .sw > .nav {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

@media screen and (max-width: 640px) {
  .add-artist .sw > .nav {
    flex-direction: column !important;
    flex: 1 auto;
  }
}

.add-artist .sw > .nav .nav-link {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
}

.add-artist .sw > .nav .nav-link:active,
.add-artist .sw > .nav .nav-link:focus,
.add-artist .sw > .nav .nav-link:hover {
  text-decoration: none;
}

.add-artist .sw > .nav .nav-link::-moz-focus-inner {
  border: 0 !important;
}

.add-artist .sw > .nav .nav-link.disabled {
  color: #ccc !important;
  pointer-events: none;
  cursor: default;
}

.add-artist .sw > .nav .nav-link.hidden {
  display: none !important;
}

.add-artist .sw.sw-justified > .nav .nav-link,
.add-artist .sw.sw-justified > .nav > li {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.add-artist .sw.sw-dark {
  color: rgba(255, 255, 255, 0.95);
  background: #000;
}

.add-artist .sw.sw-loading {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.add-artist .sw.sw-loading::after {
  content: "";
  display: block;
  position: absolute;
  opacity: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 2;
  transition: all 0.2s ease;
}

.add-artist .sw.sw-loading::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 45%;
  left: 45%;
  width: 2rem;
  height: 2rem;
  border: 10px solid #f3f3f3;
  border-top: 10px solid #3498db;
  border-radius: 50%;
  z-index: 10;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.add-artist .sw-theme-default {
  border: 1px solid #eee;
}

.add-artist .sw-theme-default > .tab-content > .tab-pane {
  padding: 10px;
}

.add-artist .sw-theme-default .toolbar > .btn {
  color: #fff;
  background-color: #17a2b8;
  border: 1px solid #17a2b8;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  font-weight: 400;
}

.add-artist .sw-theme-default > .nav {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1) !important;
}

.add-artist .sw-theme-default > .nav .nav-link {
  position: relative;
  height: 100%;
  min-height: 100%;
}

.add-artist .sw-theme-default > .nav .nav-link::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 0;
  left: 0;
  bottom: -1px;
  background: #999;
  transition: all 0.35s ease 0.15s;
}

.add-artist .sw-theme-default > .nav .nav-link.inactive {
  color: #999;
  cursor: not-allowed;
}

.add-artist .sw-theme-default > .nav .nav-link.active {
  color: #17a2b8 !important;
  cursor: pointer;
}

.add-artist .sw-theme-default > .nav .nav-link.active::after {
  background: #17a2b8 !important;
  width: 100%;
}

.add-artist .sw-theme-default > .nav .nav-link.done {
  color: #5cb85c !important;
  cursor: pointer;
}

.add-artist .sw-theme-default > .nav .nav-link.done::after {
  background: #5cb85c;
  width: 100%;
}

.add-artist .sw-theme-default > .nav .nav-link.disabled {
  color: #ddd !important;
  cursor: not-allowed;
}

.add-artist .sw-theme-default > .nav .nav-link.disabled::after {
  background: #ddd;
  width: 100%;
}

.add-artist .sw-theme-default > .nav .nav-link.danger {
  color: #d9534f !important;
  cursor: pointer;
}

.add-artist .sw-theme-default > .nav .nav-link.danger::after {
  background: #d9534f;
  width: 100%;
}

.add-artist .tab-content > .tab-pane {
  display: block;
}

.add-artist .card {
  margin-bottom: 1.875rem;
  background-color: #18042b;
  transition: all 0.5s ease-in-out;
  position: relative;
  border: 0rem solid transparent;
  border-radius: 0.75rem;
  box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
  height: calc(100% - 30px);
}

.add-artist .card-header {
  border-color: rgba(255, 255, 255, 0.2);
  position: relative;
  background: transparent;
  padding: 1.5rem 1.875rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-artist .card-title {
  font-size: 1.25rem;
  font-weight: 500;
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 0rem;
}

.add-artist h1,
.add-artist h2,
.add-artist h3,
.add-artist h4,
.add-artist h5,
.add-artist h6,
.add-artist .h1,
.add-artist .h2,
.add-artist .h3,
.add-artist .h4,
.add-artist .h5,
.add-artist .h6 {
  color: #fff;
}

.add-artist .card-body {
  padding: 1.875rem;
}

.add-artist .form-wizard {
  border: 0;
}

.add-artist .form-wizard .nav-wizard {
  box-shadow: none !important;
  margin-bottom: 2rem;
  padding-left: 114px;
}

.add-artist .form-wizard .nav-wizard li .nav-link {
  position: relative;
  width: 100%;
  background: none;
}

.add-artist .form-wizard .nav-wizard li .nav-link span {
  border-radius: 3.125rem;
  width: 3rem;
  height: 3rem;
  border: 0.125rem solid var(--primary-color);
  display: block;
  line-height: 3rem;
  color: var(--primary-color);
  font-size: 1.125rem;
  margin: auto;
  background-color: #fff;
  position: relative;
  z-index: 1;
}
.add-artist .form-wizard .nav-wizard li .nav-link.active span {
  background: #76009f;
  color: #fff;
}
.add-artist .form-wizard .nav-wizard li .nav-link:after {
  position: absolute;
  top: 50%;
  left: 24px;
  height: 0.1875rem;
  transform: translateY(-50%);
  background: #eeeeee !important;
  z-index: 0;
  width: 100%;
}
.add-artist .form-wizard .nav-wizard li:last-child .nav-link::after {
    content: none;
  }
.add-artist .sw-theme-default > .nav .nav-link.active::after {
  background: #17a2b8 !important;
  width: 100%;
}
.add-artist .form-wizard .nav-wizard li .nav-link.active:after {
  background: #76009f !important;
}
@media only screen and (max-width: 1400px) {
  .add-artist .btn {
    padding: 0.625rem 1rem;
    font-size: 0.813rem;
  }
}
.add-artist .btn-primary {
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}
.add-artist .btn.btn-success,
.add-artist .btn.btn-secondary,
.add-artist .btn.btn-warning,
.add-artist .btn.btn-primary,
.add-artist .btn.btn-danger,
.add-artist .btn.btn-info {
  color: #fff;
}
.add-artist .btn-primary:disabled,
.add-artist .btn-primary.disabled {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.add-artist .form-wizard .toolbar-bottom .btn {
  background-color: var(--primary-color);
  border: 0;
  padding: 0.75rem 1.125rem;
}
.add-artist .form-wizard .tab-content .tab-pane {
  padding: 0;
}
.add-artist .mb-2 {
  margin-bottom: 0.5rem !important;
}
.add-artist .required {
  color: #f72b50;
}
.add-artist .form-control {
  background: #fff;
  border: 0.0625rem solid #d7dae3;
  padding: 0.3125rem 1.25rem;
  color: #6e6e6e;
  height: 3.5rem;
  border-radius: 0.5rem;
}
@media only screen and (max-width: 1400px) {
  .add-artist .form-control {
    height: 2.5rem;
  }
}
.add-artist input.form-control {
  position: relative;
}

.add-artist input.form-control::placeholder{
  color: #6e6e6e;
}
.add-artist .form-control {
  background-color: #090111;
  border-color: #251a2f;
}
.add-artist .form-group {
  margin-bottom: 1rem;
}
.add-artist .form-wizard .emial-setup .mailclinet {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: #eef5f9;
  text-align: center;
  margin: auto;
}
.add-artist .form-wizard .emial-setup .mailclinet {
  background-color: #090111;
}
.add-artist .form-wizard .emial-setup .mailclinet .form-check-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.add-artist .form-wizard .emial-setup input[type="radio"] {
  display: none;
}
.add-artist .form-wizard .emial-setup .mailclinet .mail-icon {
  font-size: 3rem;
  display: inline-block;
  line-height: 1;
  margin-top: -1rem;
}
.add-artist .form-wizard .emial-setup .mailclinet .mail-text {
  font-size: 1rem;
  text-align: center;
  margin-top: 0.5rem;
}
.add-artist .form-check {
  padding-left: unset !important;
}
