/* Earnings.css */
#earnings.section {
  color: white;
  display: flex;
  flex-direction: column;
}

#earnings .title-container {
  display: flex;
  justify-content: space-between;
}

#earnings .title h1 {
  font-size: 2rem;
  letter-spacing: 0.2rem;
}

#earnings .more {
  color: var(--primary-color);
}

#earnings .earnings {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

#earnings .earning {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
}

#earnings .data {
  display: flex;
  justify-content: space-between;
}

#earnings .data h5 {
  font-weight: 100;
}

#earnings progress {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
}

#earnings progress::-webkit-progress-bar {
  border-radius: 1rem;
  height: 0.15rem;
}

#earnings progress::-webkit-progress-value {
  border-radius: 1rem;
  background-color: orange;
}

#earnings .cleared::-webkit-progress-value {
  background-color: var(--primary-color);
}

@media screen and (min-width: 280px) and (max-width: 1080px) {
  #earnings.section {
    height: 100%;
  }

  #earnings .title-container {
    flex-direction: column;
    text-align: center;
  }
}
