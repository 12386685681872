.h-100 {
    height: 100% !important;
}
.align-items-center {
    align-items: center !important;
}
.justify-content-center {
    justify-content: center !important;
}
.authincation-content {
    background: #fff;
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    border-radius: 5px;
    margin: 20px 0;
}
.auth-form {
    padding: 50px 50px;
}
.mb-3 {
    margin-bottom: 1rem !important;
}
.text-center {
    text-align: center !important;
}
.form-group {
    margin-bottom: 1rem;
}
.form-label {
    font-weight: 500;
    color: var(--bs-heading-color);
}
.mb-1 {
    margin-bottom: 0.25rem !important;
}
.form-control {
    background: #fff;
    border: 0.0625rem solid #d7dae3;
    padding: 0.3125rem 1.25rem;
    color: #6e6e6e;
    height: 3.5rem;
    border-radius: 0.5rem;
}
@media only screen and (max-width: 1400px) {
    .form-control {
        height: 2.5rem;
    }
}
input.form-control {
    position: relative;
}
.position-relative {
    position: relative !important;
}
.mb-4 {
    margin-bottom: 1.5rem !important;
}
input.form-control {
    position: relative;
}
.show-pass {
    cursor: pointer;
}
.eye {
    position: absolute;
    right: 20px;
    bottom: 16px;
}
@media only screen and (max-width: 1400px) {
    .eye {
        bottom: 7px;
    }
}
.mb-2 {
    margin-bottom: 0.5rem !important;
}
.flex-wrap {
    flex-wrap: wrap !important;
}
.d-flex {
    display: flex !important;
}
.form-check {
    display: block;
    min-height: 1.3125rem;
    padding-left: 1.5em;
    margin-bottom: 0.125rem;
}
.ms-1 {
    margin-left: 0.25rem !important;
}
.form-check {
    line-height: normal;
}
.form-check-input {
    top: 0.125rem;
    border-width: 0.125rem;
    width: 1.25rem;
    height: 1.25rem;
    border-color: #e7e7e7;
}
.form-check .form-check-input {
    float: left;
    margin-left: -1.5em;
}
.form-check-input[type="checkbox"] {
    border-radius: 0.25em;
}
label {
    display: inline-block;
    margin-bottom: 0.5rem;
}
.form-check-label {
    margin-left: 0.3125rem;
    margin-top: 0.1875rem;
}
.ms-2 {
    margin-left: 0.5rem !important;
}
.text-center {
    text-align: center !important;
}
.btn {
    padding: 0.938rem 1.5rem;
    border-radius: 0.5rem;
    font-weight: 400;
    font-size: 1rem;
}
@media only screen and (max-width: 1400px) {
    .btn {
        padding: 0.625rem 1rem;
        font-size: 0.813rem;
    }
}
.btn-primary {
    border-color: #76009f;
    background-color: #76009f;
}
.btn-block {
    display: block;
    width: 100%;
}
.auth-form .btn {
    height: 50px;
    font-weight: 700;
}